
.checkmark {
    display:inline-block;
    width: 22px;
    height:15px;
    -ms-transform: rotate(30deg); /* IE 9 */
    -webkit-transform: rotate(30deg); /* Chrome, Safari, Opera */
    transform: rotate(30deg);
}

.checkmark_stem {
    position: absolute;
    width: 2px;
    height: 12px;
    background-color: #344A62;
    left: 13px;
    top: 2px;
} 

.checkmark_kick {
    position: absolute;
    width: 5px;
    height: 2px;
    background-color: #344A62;
    left: 8px;
    top: 12px;
}
