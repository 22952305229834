.ag-canvas {
    display: flex;
    align-items: center;
    justify-items: center;
    /* height: 40rem; */
    padding-top: 1rem;
    justify-content: space-around;
}

#ag-canvas {
    height: 100%;
}

.ag-item :first-child {
    border-radius: 6px;
}

.ag-item {
    border-radius: 6px;
    border: 2px #00B6ED solid;
    height: 100%;
    width: 100%;
}

.ag-btn-group {
    margin-top: 2rem;
    height: 70px;
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ag-btn {
    color: white;
    cursor: pointer;
    font-size: 60px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.control-button-text {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    color: #F5F7FA;
}