.appointment-hr {
  display: block;
  margin-top: 0em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.appointment-card {
    border: 0.1rem solid black;
    height: fit-content;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    padding: 0.5rem;
    white-space: nowrap;
}

.appointment-text {
    color: var(--pollie-primary);
    margin-bottom: 0.5rem;
}

.add-to-calendar-link {
    padding-top: 1rem;
}

.add-to-calendar-text, .add-to-calendar-text:hover {
    cursor: pointer;
    color: var(--pollie-secondary-dark);
    text-decoration: underline;
}

.cancel-appointment-link, .cancel-appointment-link:hover {
    cursor:pointer;
    color: var(--pollie-secondary-dark);
    text-decoration: underline;
}

.appointment-nav {
    cursor: pointer;
    color: var(--pollie-text-grey);
    white-space: pre;
}

.appointment-nav-selected {
    cursor: pointer;
    color: var(--pollie-text-dark);
    border-bottom: solid 5px #1E6A76;
    white-space: pre;
}

.appointment-nav-selected:hover, .appointment-nav:hover {
    text-decoration: none;
}

.modal-header {
    border-bottom: 0 none;
}

.modal-body {
    padding: 2rem;
}

.modal-width {
    max-width: 20em;
    margin-left: auto;
    margin-right: auto;
}

.note-link {
    cursor: pointer;
    width: fit-content;
}

.edit-note-link {
    cursor: pointer;
    display: flex;
    align-items: flex-end;
}

.note-container {
    min-height: 25rem;
}

.note-timestamp {
    font-size: 18px;
    line-height: 28px;
}

.note-card {
    border: 0.1rem solid black;
    border-radius: 0.5rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
}

.view-note-button {
    border: 0.1rem solid black;
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
}