.meeting.wrapper {
    height: 100%;
    background-color: #212121;
    padding-bottom: 13rem;
}

/* video {
    max-width: unset;
} */

.meeting .ag-footer {
    padding: 0 30px;
    display: flex;
    align-items: center;
}

.meeting .ag-main {
    position: relative;
    text-align: -webkit-center;
}

.meeting .ag-footer :first-child {
    margin-right: 80px;
}

.ag-container {
    width: calc(100% - 60px);
    height: 100%;
    margin: 0 auto;
    border-radius: 12px;
}

.btn-view-member, .btn-view-member:hover {
    border-radius: 8px;
    background: var(--pollie-secondary-dark);
    color: var(--pollie-white);
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin-top: 2rem;
}

.member-profile-icon {
    padding-right: 0.5rem;
    padding-bottom: 0.1rem;
}