/* override day number color and size */
.fc-col-header-cell-cushion, .fc-col-header-cell-cushion:hover {
  font-size: 22px;
  color: #212529;
}

/* override day title color and size */
.fc-daygrid-day-number, .fc-daygrid-day-number:hover {
  font-size: 22px;
  color: #212529;
}

.fc-event,
.fc-event:hover,
.fc-list-day-text,
.fc-list-day-text:hover,
.fc-list-day-side-text,
.fc-list-day-side-text:hover {
  color: #212529;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.fc-daygrid-day-events {
  font-size: 14px;
}

.fc-button {
    font-family: futura-pt,sans-serif !important;
    padding: 0.15em 0.3em !important;
    background-color: #1E6A76 !important;
}

th {
    font-weight: normal !important;
}

.fc-daygrid-day-top {
    flex-direction: initial !important;
}

.fc-button-active {
    background-color: #344A62 !important;
}

.fc-toolbar-title {
    font-family: futura-pt,sans-serif !important;
}

.fc-daygrid-day-number {
    font-size: 18px !important;
}

.placeholder-opacity::placeholder {
    opacity: 0.5;
}

.calendar-date {
    font-size: 18px;
}

.fc-daygrid-day.fc-day-today {
    background-color: inherit !important;
}

.edit-availability-modal-container {
    z-index: 9999;
}

.more-link {
    color: blue;
}

.fc-popover {
    z-index: 5;
}

.fc-popover-title {
    width: min-content;
    text-align: center;
    margin: 0px 35% !important;
}

.fc-popover {
    z-index: 2;
}

.fc-daygrid-day-events {
    min-height: 4rem !important;
}

.cursor-pointer {
    cursor: pointer;
}

.fc-daygrid-day-number {
    cursor: default;
    text-decoration: none !important;
}