.page-footer {
  background-color: var(--pollie-primary);
  color: var(--pollie-white);
}

.footer-link, .footer-link:hover {
  font-size: 18px;
  color: var(--pollie-white);
}

.footer-email, .footer-email:hover {
  font-size: 18px;
  color: var(--pollie-white);
  text-decoration: underline;
}

.footer-hr {
  display: none;
}

/* Responsive */
@media (max-width: 767px) {
  .footer-column {
    text-align: left;
    padding-left: 40px;
  }

  .footer-hr {
    display: block;
    border-color: var(--pollie-accent-grey-light);
    width: calc(100% + 25px) !important;
    margin-left: -25px;
  }

  .footer-header {
    margin-top: 20px;
  }

  .footer-instagram > img {
    margin-bottom: 10px;
  }
}