.provider-go-back {
    margin-bottom: 50px;
    cursor: pointer;
    max-width: 6rem;
}
  
.provider-go-back > a, .provider-go-back > a:hover {
    text-decoration: underline;
    color: var(--pollie-secondary-dark);
}

.provider-section {
    margin-bottom: 40px;
}

.provider-name {
    font-weight: 700;
}

.provider-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1rem;
}

.provider-video-bio, .provider-video-bio:hover {
    text-decoration: underline;
    color: var(--pollie-secondary-dark);
}

.provider-video-bio > img {
    margin-right: 14px;
}

.provider-booking-card {
    border: 1px solid var(--pollie-text-dark);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 36px 32px;
    margin-top: 100px;
}

.provider-book-appointment {
    margin-bottom: 20px;
}

.provider-profile-mobile-details {
    display: none;
}

.provider-book-appointment-mobile {
    display: none;
}

.provider-card {
    padding: 40px 25px;
    border-radius: 16px;
    width: 100%;
}

.provider-bio {
    font-size: 18px;
}

.provider-types {
    margin-top: 10px;
    font-size: 18px;
    margin-bottom: 15px;
}

.book-appointment-link-mobile, .book-appointment-link-mobile:hover {
    color: var(--pollie-text-light);
    text-decoration: none;
}

.track-health-link, .track-health-link:hover {
    text-decoration: underline;
    color: var(--pollie-secondary-dark);
    cursor: pointer;
}

.cancel-modal .cancel-modal:hover {
    cursor: pointer;
    color: var(--pollie-text-dark) !important;
}

.my-health {
    font-family: Calluna;
    font-weight: 900;
    font-size: 36px;
    line-height: 52px
}

.my-health-date {
    font-size: 22px;
    line-height: 38px;
    padding-bottom: 1rem;
}

.emoji {
    font-size: 48px;
    line-height: 28px;
    padding: 0.5rem;
}

.btn-mood {
    margin-right: 1rem !important;
    padding: 0.5rem !important;
    font-weight: normal !important;
}

.hormone-label {
    padding-bottom: 1rem;
}

.symptom-button {
    margin-right: 0.5rem !important;
    margin-bottom: 1rem;
    border-radius: 40px !important;
    text-align: center !important;
    vertical-align: center;
    padding: 1px 1px !important;
}

.symptom-text {
    margin-right: 1rem;
    margin-left: 1rem;
    font-weight: normal !important;
}

.health-modal-header-left {
    float: left;
}

.health-modal-header-right {
    float: right;
}

.hormone-modal-width {
    max-width: 30em;
    margin-left: auto;
    margin-right: auto;
}

.modal-body {
    padding: 1rem !important;
}

/* Responsive */
@media (max-width: 991px) {
    .provider-profile-mobile-details {
        display: block;
        margin-bottom: 50px;
    }

    .provider-book-appointment-mobile {
        display: block;
        position: fixed;
        width: 100%;
        height: 60px;
        color: var(--pollie-text-light);
        background: var(--pollie-secondary-dark);
        bottom: 0;
        z-index: 1;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        padding-top: 15px;
    }
}

.dashboard-module {
    border: 0.1rem solid black;
    border-radius: 1rem;
    align-items: center;
    height: fit-content;
}

.dashboard-module-row {
    border: 0.1rem solid black;
    border-radius: 0.5rem;
    align-items: center;
}

.dashboard-btn-dark, .dashboard-btn-dark:hover {
    border-radius: 1rem;
    background: var(--pollie-secondary-dark);
    color: var(--pollie-white);
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    line-height: 38px;
}

.dashboard-btn-light, .dashboard-btn-light:hover {
    border-radius: 1rem;
    background: var(--pollie-accent-grey-light);
    color: var(--pollie-white);
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    line-height: 38px;
}

.subscription-label, .subscription-label:hover {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
}

.appointment-approaching {
    font-size: 18px;
}

.appointment-soon {
    color: var(--pollie-accent-orange-light);
}