.messages-link {
    display: inline-block;
}

.member-profile-overview-container {
    height: fit-content;
    width: fit-content;
    padding: 1rem;
}

.edit-plan-button {
    height: fit-content;
    align-self: center;
}

.plan-date {
    font-size: 18px;
}

.draft-container {
    border: 0.1rem solid black;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.current-plan-container {
    border: 0.1rem solid black;
    border-radius: 0.3rem;
    padding: 1rem;
}