body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
  font-family: futura-pt,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--pollie-white)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --pollie-primary: #344A62;
  --pollie-secondary-dark: #1E6A76;
  --pollie-secondary-light: #2B97A8;
  --pollie-text-dark: #223040;
  --pollie-text-medium: #BDBDBD;
  --pollie-text-light: #F5F7FA;
  --pollie-text-grey: #828282;
  --pollie-accent-grey-dark: #ADBFCF;
  --pollie-accent-grey-light: #D3DDE5;
  --pollie-accent-orange-dark: #C66301;
  --pollie-accent-orange-light: #EE9B48;
  --pollie-white: #FCFCFC;
}

h1 {
  font-family: calluna, serif;
  font-weight: 900;
  font-style: normal;
  font-size: 36px;
}

label {
  font-size: 18px;
}

.btn {
  white-space: nowrap;
}

.form-control {
  font-size: 22px;
  height: 60px;
  padding: 12px 24px;
}

.loader {
  width: 100%;
  /*height: 300px;*/
  text-align: center;
  padding-top: 6px;
}

.bold {
  font-weight: 700;
}

.thin {
  font-weight: 300;
}

.btn-pollie-light-outline, .btn-pollie-light-outline:hover {
    box-sizing: border-box;
    border-radius: 8px;
    background: var(--pollie-white);
    color: var(--pollie-secondary-dark);
    border: 1px solid var(--pollie-secondary-dark);
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    padding: 12.5px 16px;
}

.btn-pollie-dark, .btn-pollie-dark:hover {
  box-sizing: border-box;
  border-radius: 8px;
  background: var(--pollie-secondary-dark);
  color: var(--pollie-white);
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  padding: 12.5px 16px;
}

.btn-pollie-danger, .btn-pollie-danger:hover {
  box-sizing: border-box;
  border-radius: 8px;
  background: var(--pollie-accent-orange-dark);
  color: var(--pollie-white);
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  padding: 12.5px 16px;
}

.btn-pollie-outline, .btn-pollie-outline:hover {
  border: 1px solid var(--pollie-text-dark);
  background: var(--pollie-white);
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--pollie-text-dark);
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  padding: 12.5px 16px;
}

.pollie-input {
  border-radius: 8px;
  padding: 12.5px 16px;
}

.pollie-link, .pollie-link:hover {
  text-decoration: underline;
  color: var(--pollie-secondary-dark);
}

.center-text {
  text-align: center;
}

.category-headers {
  font-size: 18px;
  font-weight: 700;
}

.not-found {
  margin-top: 100px;
  margin-bottom: 200px;
}

.auth {
  margin-top: 60px;
  margin-bottom: 60px;
}

.auth-subtitle {
  margin-bottom: 55px;
  margin-top: 25px;
}

.auth-link {
  text-decoration: underline;
  color: var(--pollie-text-dark);
}

.auth-link-spacing {
  margin-top: 40px;
}

.provider-type-bubble {
  border: 1px solid var(--pollie-text-dark);
  border-radius: 18px;
  height: 36px;
  padding: 8px 14px;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
}

.member-match-contact-email, .member-match-contact-email:hover {
  text-decoration: underline;
  color: var(--pollie-text-dark);
}

.profile-spacing {
  margin-bottom: 200px;
  margin-top: 40px;
}

.provider-logo-image {
  object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.matched-member-email-link, .matched-member-email-link:hover {
  color: var(--pollie-text-dark);
  text-decoration: underline;
}

.member-logo-image-generated {
  width: 80px;
  height: 80px;
  background: #d5dfe6;
  border-radius: 40px;
  color: white;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  padding-top: 18px;
}

.member-header {
  margin-top: 2rem;
}

.member-header-text {
  margin-bottom: 40px;
}

.member-match-buttons {
  margin-bottom: 35px;
}

.member-matches-footer {
  margin-bottom: 120px;
}

.member-matches-footer-text {
  margin-top: 20px;
  margin-bottom: 30px;
}

.member-card {
  padding: 40px 25px;
  border-radius: 16px;
  width: 100%;
}

.profile-section {
  margin-bottom: 30px;
}

.profile-section-header {
    font-size: 18px;
    margin-bottom: 5px;
}

.profile-section-header-bold {
  margin-bottom: 5px;
  font-weight: bold
}

.profile-links {
  padding-right: 60px;
  margin-bottom: 60px;
}

.profile-nav-link, .profile-nav-link:hover {
  cursor: pointer;
  text-decoration: none;
  color: #212529;
}

.profile-nav-sub-links {
  margin-left: 40px;
}

.profile-incomplete {
  color: var(--pollie-text-medium);
}

.card {
  background-color: #FCFCFC;
}

.no-transition {
  -webkit-transition: height 0.01s;
  -moz-transition: height 0.01s;
  -ms-transition: height 0.01s;
  -o-transition: height 0.01s;
  transition: height 0.01s;
}

.provider-networking {
  margin-bottom: 100px;
}

/* Responsive */
@media (max-width: 767px) {
  .view-profile-button {
    width: 100%;
    margin-top: 20px;
  }

  .provider-match-buttons {
    margin-bottom: 0px;
  }
}

.pollie-underline, .pollie-underline:hover {
    border-bottom: 0.5rem solid var(--pollie-secondary-dark);
}
