.message-sender {
    width: 100%;
    margin-top: 0.5rem;
}
  
/*
.button {
    background-color: var(--pollie-secondary-dark);
    width: 20%;
    height: 60px;
    border: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .6);
} */

.send-message-btn {
    bottom: 0.3rem;
    right: 0rem;
    width: 10%;
    position: absolute;
    border: none;

    box-sizing: border-box;
    border-radius: 8px;
    background: var(--pollie-secondary-dark);
    color: var(--pollie-white);
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
}

.input-container, .input-container:focus, .input-container:focus-visible {
    background-color: #F2F2F2;
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    color: #828282;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    outline: none;
    position: relative;
}

.input-box, .input-box:focus, .input-box:focus-visible {
    background-color: #F2F2F2;
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    color: #828282;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 5.5rem;
    outline: none;
    position: absolute;
    min-height: 2.5rem;
}

textarea {
    resize: none;
    overflow-x: hidden;
    overflow-y: hidden;    
}