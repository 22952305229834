.message {
    font-size: .9em;
    width: auto;
    padding: 7px;
    display: block;
    border: none;
    max-width: 60%;
    text-align: start;
    border-radius: 7px;
    white-space: break-spaces;
}

.provider-message {
    background-color: #D3DDE5;
}

.member-message {
    background-color: #F2F2F2;
}

.timestamp {
    align-self: flex-end;
    justify-content: center;
    font-size: 18px;
    line-height: 28px;
    color: #828282;
}

.focus-message-timestamp {
    font-size: small;
    padding: 7px;
    /* align-self: flex-end; */
}

.message-group {
    display: block;
}

.focus-message-placeholder {
    margin-top: 2.05rem;
}