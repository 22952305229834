#provider-specialties-dropdown-menu {
    border-radius: 0.25rem !important;
    height: 2.5rem;
    border-color: var(--pollie-accent-grey-light);
    font-family: futura-pt,sans-serif;
    font-size: 17px;
}

input[type="radio"] {
    display: none;
}

input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  border: 1px solid var(--pollie-accent-grey-light);
  border-radius: 50%;
}

input[type="radio"]:checked + label:before {
    border: 6px solid var(--pollie-secondary-dark);
}

.providerPricingSelect {
    border-radius: 0.25rem;
    width: 100%;
    height: 2.5rem;
    border-color: var(--pollie-accent-grey-light);
    font-family: futura-pt,sans-serif;
    font-size: 17px;
}

.file-uploader input[type="file"] {
    display: none;
}

.file-uploader label {
    cursor: pointer;
    align-self: center;
    padding-left: 2rem;
    text-decoration: underline;
    color: var(--pollie-secondary-dark);
    font-weight: 400;
    font-size: 20px;
    line-height: 38px;
}

.sign-out-link {
    color: var(--pollie-accent-grey-dark)
}