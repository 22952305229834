.call {
  text-align: center;
}

.player-container {
  padding: 20px;
  text-align: initial;
}

.button-group {
  padding: 20px;
}

.call-form {
  padding: 20px;
}

input, button {
  margin-right: 10px;
}

#videos{
  position: relative;
  height: 85vh;
  width: 100vw;
  margin: auto;
  align-self: flex-start;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  justify-items: center;
  align-items: center;
}

.controls{
  left: 50%;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  align-items: center;
  justify-items: center;
  width: 500px;
  max-width: 60vw;
}

.video-placeholder {
    background: #C4C4C4;
    width: 48%;
    height: 30rem;
}