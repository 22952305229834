.pollie-navbar {
  background-color: var(--pollie-white);
  height: 104px;
  font-size: 18px;
}
.nav-link {
    color: var(--pollie-text-dark);
    white-space: nowrap;
    padding: 0rem !important;
}

.loggedin > a {
  margin: auto auto;
}

.loggedin > a > img {
  height: 40px;
}

.pollie-navbar-subtitle {
  margin-left: 45px;
  position: relative;
  top: 5px;
  font-size: 18px;
  line-height: 28px;
}

.profile-initials {
  width: 40px;
  height: 40px;
  background: #d5dfe6;
  border-radius: 20px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-top: 6px;
}

.navbar-logo-image {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.pollie-logo {
    object-fit: cover;
    width: 100px;
    /* height: 48px; */
}

.notifications-mobile {
  float: right;
  margin-right: 20px;
}

.profile-image-mobile {
  float: right;
}

.profile-image-mobile > a, .profile-image-mobile > a:hover{
  text-decoration: none;
}

.navbar-mobile-signed-in {
  position: absolute;
  right: 20px;
  top: 32px;
}

.navbar-toggle-signed-in {
  position: absolute;
  left: 20px;
  top: 34px;
}

.mobile-nav {
  position: fixed;
  top: 0;
  width: 90%;
  background: var(--pollie-primary);
  z-index: 2;
  height: 100%;
}

.mobile-nav-toggle-image {
  height: 26px;
}

.mobile-nav-close {
  position: absolute;
  right: 20px;
  top: 20px;
}

.mobile-nav-close-image {
  height: 14px;
}

.nav-link-mobile, .nav-link-mobile:hover {
  padding-left: 40px !important;
  color: var(--pollie-white);
}

button, button:focus {
  outline-color: transparent;
  outline-style: none;
}

.nav-new-messages, .nav-new-messages:hover {
    color: var(--pollie-white);
    background-color: #C66301;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    display: inline-block;
    text-decoration: none;
    text-align: center;
}

