.pollie-appointment-item {
    border: 0.1rem solid var(--pollie-secondary-dark);
    background-color: var(--pollie-secondary-dark);
    border-radius: 0.1rem;
    overflow: hidden;
    color: var(--pollie-white);
    width: 100%;
}

.appointment-event, .appointment-event:hover {
    cursor: pointer;
    background: none;
    padding: 0rem;
    margin: 0.1rem 0rem 0rem 0rem !important;
}

.fc-daygrid-day-events {
    margin-bottom: 0.5rem !important;
}

.fc-popover-header {
    background: var(--pollie-white) !important;
}

.appointment-view-modal {
    max-width: max-content;
}