.search-form {
  width: inherit;
  display: flex;
  align-items: center;
}

.mag-glass-icon {
  position: absolute;
  pointer-events: none;
  padding-left: 0.5rem;
}

.mag-glass-icon + input {
  padding-left: 2rem;
  border: none;
  background-color: var(--pollie-text-light);
  border-radius: 0.25rem;
  width: inherit;
}