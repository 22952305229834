.message-container {
    height: 40rem;
    border: 0.1rem solid #C4C4C4;
    border-radius: 0.5rem;
    width: 85%;
    max-width: 80rem;
}

.member-messaging-card {
    height: 4rem;
    align-content: center;
    border-style: solid;
    margin-bottom: 0.5rem;
    border-color: var(--pollie-accent-grey-light);
    border-radius: 0.5rem;
    text-align: left;
    align-items: center;
    border-width: thin;
}

.chat-header {
    height: 4rem;
    font-weight: bold;
}

.selected {
    border-color: var(--pollie-black);
}

.new-message, .new-message:hover {
    white-space: nowrap;
    font-weight: normal !important;
    border-radius: 1rem;
    width: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.members-chat-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 40rem;
    padding-top: 1rem;
}

.message-chat-header {
    border-bottom: 0.1rem solid #C4C4C4;
    height: 10%;
}

.message-container-left {
    border-right: 0.1rem solid #C4C4C4;
    height: 90%;
}

.message-container-right {
    height: 90%;
}

.message-member-title {
    border-right: 0.1rem solid #C4C4C4;
}

.chat-area-container {
    height: 90%;
}

.display-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    font-size: 18px;
    line-height: 28px;
}

.most-recent-message-time {
    text-align: end;
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: clip;
    font-size: 18px;
    line-height: 28px;
}