.note-modal-width {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
}

.note-text {
    white-space: break-spaces;
}

.note-text-area {
    overflow-y: auto;
}

.note-footer {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    color: var(--pollie-secondary-dark);
}