.my-members-row {
    border: 0.1rem solid var(--pollie-accent-grey-light);
    border-radius: 0.5rem;
    align-items: center;
    cursor: pointer;
}

.my-members-row-selected {
    border: 0.1rem solid black;
    border-radius: 0.5rem;
    align-items: center;
    cursor: pointer;
}

.member-profile-container {
    height: fit-content;
    width: fit-content;
    padding: 1rem;
    border: 0.1rem solid black;
    border-radius: 0.5rem;
}

.member-name-header {
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
}

.member-profile-field {
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    color: #828282;
    margin-top: 1rem;
}

.member-profile-value {
    font-size: 22px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0px;
    width: max-content;
}


.search-form {
    width: inherit;
    display: flex;
    align-items: center;
}

.mag-glass-icon {
    position: absolute;
    pointer-events: none;
    padding-left: 0.5rem;
}

.mag-glass-icon + input {
    padding-left: 2rem;
    border: none;
    background-color: var(--pollie-text-light);
    border-radius: 0.25rem;
    width: inherit;
}

.dropdown-button, .dropdown-button:hover, .dropdown-button:active, .dropdown-button.active, .dropdown-button:focus {
    background: none !important;
    border: none !important;
    color: var(--pollie-text-dark) !important;
    background-color: white !important;
    box-shadow: none !important;
}

.dropdown-item {
    color: var(--pollie-text-dark);
}

.dropdown-button:active, .dropdown-item.active {
    padding-left: 0rem;
    color: var(--pollie-text-dark);
}

.dropdown-item:hover {
    background-color: #344A62;
}

.dropdown-item:active, .dropdown-item.active {
    background-color: white;
    color: var(--pollie-text-dark);
}
