.pagination {
    justify-content: center;
} 

.page-link {
    background-color: var(--pollie-secondary-dark);
    border-color: transparent !important;
}

.page-link:hover, .page-link-active {
    background-color: var(--pollie-secondary-light) !important;
}