

.lab-name {
    font-size: 18px;
    line-height: 28px;
}

.lab-dropdown-button, .lab-dropdown-button:hover, .lab-dropdown-button:active, .lab-dropdown-button.active, .lab-dropdown-button:focus {
    background: none !important;
    color: var(--pollie-text-dark) !important;
    background-color: white !important;
    box-shadow: none !important;
    border: 0.1rem solid #D3DDE5 !important;
    border-radius: 0.3rem;
    padding-left: 0.5rem !important;
    display: flex;
    align-items: center;
}

.dropdown-item {
    color: var(--pollie-text-dark);
}

.lab-dropdown-button:active, .dropdown-item.active {
    padding-left: 0rem;
    padding-right: 0rem;
    color: var(--pollie-text-dark);
}

.dropdown-item:hover {
    background-color: #344A62;
}

.dropdown-item:active, .dropdown-item.active {
    background-color: white;
    color: var(--pollie-text-dark);
    padding: .25rem 0rem;
}

.lab-dropdown-button::after {
    position: absolute;
    right: 1rem;
}

.low-labs {
    color: #EE9B48;
}

.high-labs {
    color: #F42C3C;
}

.new-lab-name {
    padding-left: 0.5rem !important;
    margin: 0rem;
    border: 0.1rem solid #D3DDE5;
    border-radius: 0.3rem;
}

.delete-icon {
    cursor: pointer;
}

.delete-tag-icon {
    cursor: pointer;
    margin-right: 1rem;
}

.backdrop {
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
}

.delete-modal-body {
    padding-top: 0rem !important;
}

.delete-button {
    width: 45%;
}

.add-lab {
    cursor: pointer;
}

.lab-name-dropdown-button, .lab-name-dropdown-button:hover, .lab-name-dropdown-button:active, .lab-name-dropdown-button.active, .lab-name-dropdown-button:focus {
    background: none !important;
    color: var(--pollie-text-dark) !important;
    background-color: white !important;
    box-shadow: none !important;
    padding-left: 0.5rem !important;
    display: flex;
    align-items: center;
    padding: 0rem !important;
    margin: 0rem !important;
    border: none !important;
}

.lab-name-dropdown-button:active, .dropdown-item.active {
    border: none !important;
    color: var(--pollie-text-dark);
    padding: 0rem !important;
    margin: 0rem !important;
}

.lab-name-dropdown-button::after {
    display: none;
}

.add-lab-container {
    width: fit-content;
}

.lab-name-dropdown {
    width: 80%;
    height: 28px;
}

.pcos-plan-text {
    width: 98%;
    overflow-y: auto;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    border-color: #D3DDE5;
    white-space: pre-wrap;
}

.pcos-plan-category-container {
    /* height: 10rem; */
    margin-bottom: 2rem;
}

.delete-text, .delete-text:hover {
    font-weight: normal;
}

.sub-header-text {
    font-size: 18px;
}

.tags-container {
    width: "100%";
    margin-top: 1rem;
    flex-direction: row;
    margin-right: 0.5rem;
    margin-left: 0rem;
}

.tag-container {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    border: .1rem solid #000;
    width: fit-content;
    border-radius: 12rem;
    padding: 0.25rem 0.75rem;
    font-size: 18px;
}

.add-new-tags-plus {
    color: #1E6A76;
}

.add-new-tags {
    color: #1E6A76;
    text-decoration: underline;
}

.tag-dropdown-container {
    width: 10rem;
}