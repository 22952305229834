.form-submit-button {
    margin-top: 2rem;
    width: 100%;
}
  
.form-error {
    color: var(--pollie-accent-orange-dark);
}

.error-link, .error-link:hover {
    color: var(--pollie-accent-orange-dark);
    text-decoration: underline;
}

.reset-link, .reset-link:hover {
    color: var(--pollie-secondary-dark);
    text-decoration: underline;
}